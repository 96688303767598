import { truncate, dateFormater } from '@/filters'

export default {
  name: 'DonationItem',
  filters: {
    truncate,
    dateFormater
  },
  props: {
    donation: {
      type: Object,
      default: {}
    }
  },
  computed: {
    informationData () {
      return this.donation.information || {}
    }
  },
  methods: {
    handleDeleteDonation () {
      this.$emit('handleDeleteDonation', this.donation.id)
    },
    handleEditDonation () {
      this.$emit('handleEditDonation', this.donation.id)
    }
  }
}
